import request from '@/utils/request'

export function getOrderList(data){
    return request({
        url: '/orders/getOrderList',
        method: 'post',
        data,
      });
}

export function getOrderDetail(data){
    return request({
        url: '/orders/getOrderDetail',
        method: 'post',
        data,
      });
}

export function createOrder(data){
  return request({
      url: '/orders/createOrder',
      method: 'post',
      data,
    });
}

export function createAdditionOrderItem(data){
  return request({
      url: '/orders/createAdditionOrderItem',
      method: 'post',
      data,
    });
}

export function checkOrderRepeated(data){
  return request({
      url: '/orders/checkOrderRepeated',
      method: 'post',
      data,
    });
}

export function updateOrderDetailAssignQuantity(data){
  return request({
      url: '/orders/updateOrderDetailAssignQuantity',
      method: 'post',
      data,
    });
}

export function deleteOrder(data){
  return request({
      url: '/orders/deleteOrder',
      method: 'post',
      data,
    });
}

export function postExportDailyMeetSummary(data){
  return request({
      url: '/orders/exportDailyMeetSummary',
      method: 'post',
      data,
    });
}

export function getDailyOrderStatus(data){
  return request({
      url: '/orders/getDailyOrderStatus',
      method: 'post',
      data,
    });
}

export function postHistoryOrder(data){
  return request({
      url: '/orders/postHistoryOrder',
      method: 'post',
      data,
    });
}